import React, {useState, useEffect } from "react";
import {useParams} from "react-router-dom"
import {dataTableColumns2 } from "../components/table/TableData";
import Content from "../../layout/content/Content";
import { Label,Input,Row, Col } from "reactstrap";
import {
    Block,
    PreviewCard,
    Icon,
  } from "../../components/Component";
import Head from "../../layout/head/Head";
import { toast } from "react-toastify";
import { getCookie } from "../../utils/Utils";


const ContactInformation = () => {
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [SpecificUser, setUser] = useState([]);
    const [isVerifyEmailToggled, setIsVerifyEmailToggled] = useState(false);
    const id = useParams(); 
    const userId = parseInt(id.userId);
    const [formdata, setFormData] = useState({
        email: "",
        phone_number: "",
        verify_email:"0",
        country: "",
      });

    useEffect(() => {
        const fetchCountriesFromAPI = async () => {
          try {
            const authToken = getCookie("authToken");
      
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/countries`, {
              headers: {
                authToken: authToken,
              },
              method: "GET",
            });
      
            if (response.ok) {
              const responseData = await response.json();
                if (responseData.status) {
                  setCountries(responseData.data.countries);
                } else {
              }
            } else {
                console.log("Error fetching blogs:", response.statusText);
            }
          } catch (error) {
            console.error("Fetch error:", error);
          } finally {
            setLoading(false); 
          }
        };
        fetchCountriesFromAPI();
      }, []);

    //update contact information
      useEffect(() => {
        const fetchSpecificUserData = async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/admin/user-management/get-specific-user`;
                const formData = new FormData();

                formData.append("id", userId);
                const response = await fetch(apiUrl, {
                    headers: {
                        authToken: getCookie("authToken"),
                    },
                    method: "POST",
                    body: formData,
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data.status) {
                        const user_data = data.data.user;
                        setUser(data.data?.user);
                        setFormData({
                            email: user_data.email || "",
                            phone_number: user_data.phone_number || "",
                            verify_email: user_data.verify_email || 0,
                            country: user_data.country || "",
                          });
                    } else {
                        console.error("Error fetching blog data", data.message);
                    }
                } else {
                    console.error("Error fetching blog data", response.statusText);
                }
            } catch (error) {
                console.error("Fetch error:", error);
            }
        };
        fetchSpecificUserData();
    }, [id]);
    
      const handleUpdateContact = async () => {
        try {
          const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/admin/user-management/update-contact-Information`;
          const formData = new FormData();
          formData.append("id", userId);
          formData.append("email", formdata.email);
          formData.append("phone_number", formdata.phone_number);
        //   formData.append("verify_email", formdata.verify_email);
        if (isVerifyEmailToggled) {
            formData.append("verify_email", formdata.verify_email);
          }
    
          const response = await fetch(apiUrl, {
            headers: {
              authToken: getCookie("authToken"),
            },
            method: "POST",
            body: formData,
          });
    
          if (response.ok) {
            const data = await response.json();
            if (data.status) {
              toast.success("Contact information updated successfully");
              setTimeout(() => {
              }, 3000);
            } else {
                if (data?.errors) {
                    toast.error(data?.errors[0]);
                } else {
                    toast.error(data.message);
                }
            }
          } else {
            console.error("Error updating profile", response.statusText);
          }
        } catch (error) {
          console.error("Update error:", error);
        }
      };

      const handlePhoneNumberKeyDown = (e) => {
        if (["e", "E", "+", "-", "."].includes(e.key)) {
            e.preventDefault();
        }
    };
      
  return (
    <Block size="lg">
        <PreviewCard>
            <div className="card-head">
                <h5 className="card-title">Contact Information</h5>
            </div>
            <Row className="g-4">
                <Col sm="6" className="">
                    <div className="form-group">
                        <Label className="form-label" htmlFor="email">Email</Label>
                        <Input name="email" type="text" className="form-control " id="email"  value={formdata.email}
                        onChange={(e) => setFormData({ ...formdata, email: e.target.value })} required />
                    </div>
                    <Row>
                    <Label className="form-label" htmlFor="phone_no">Phone Number</Label>
                    <Col className="col-sm-4">
                            <div className="form-group">
                                <div className="form-control-wrap">
                                    <div className="form-control-select">
                                        <Input type="select" name="country" id="country" value={formdata.country} onChange={(e) => setFormData({ ...formdata, country: e.target.value })}>
                                        {countries.map((country, index) => (
                                            <option key={index} value={country.phonecode} >
                                            (+{country.phonecode}) {country.name}
                                        </option>
                                        ))}
                                        </Input>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className="col-sm-8 px-0">
                            <div className="form-group">
                            <Input name="phone_number" type="number" className="form-control " id="phone_number"  value={formdata.phone_number}
                        onChange={(e) => setFormData({ ...formdata, phone_number: e.target.value })} onKeyDown={handlePhoneNumberKeyDown} required />
                            </div>
                        </Col>
                    </Row>
                </Col>
                {/* <Col  sm="6" className="pt-5 pt-sm-3"> */}
                    {/* <Col sm="6" className="" style={{ padding: '15px 0px' }}>
                        <div className="custom-control custom-switch checked">
                        <Input type="checkbox" className="custom-control-input"  id="verify_button" />
                        <Label className="custom-control-label" htmlFor="verify_button">Account Verification Status</Label>
                        </div>
                    </Col> */}
                    <Col sm="6">
                        <Label className="form-label" htmlFor=""></Label>
                        <div className="form-group">
                            <div className="form-control-wrap">
                                <div className="preview-block">
                                    <div className="custom-control custom-switch">
                                        {/* <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        defaultChecked
                                        id="verify_email"
                                        onClick={() =>
                                            setFormData({
                                                ...formdata,
                                                verify_email: formdata.verify_email === '0' ? '1' : '0',
                                            })
                                            }  checked={formdata.verify_email === '1'}
                                        /> */}
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            defaultChecked
                                            id="verify_email"
                                            onClick={() => {
                                                setFormData({
                                                ...formdata,
                                                verify_email: formdata.verify_email === '0' ? '1' : '0',
                                                });
                                                setIsVerifyEmailToggled(true);
                                            }} checked={formdata.verify_email === '1'}
                                        />
                                        <label className="custom-control-label form-label" htmlFor="verify_email">
                                        Email Verification Status
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    {/* <Col sm="6" className="" style={{ padding: '15px 0px' }}>
                        <div className="custom-control custom-switch checked">
                        <Input type="checkbox" className="custom-control-input"  id="verify_mobile" />
                        <Label className="custom-control-label" htmlFor="verify_mobile">Mobile Verification Status</Label>
                        </div>
                    </Col> */}
                {/* </Col> */}
                <Col className="col-12">
                    <div className="form-group">
                        <button id="saveContactInformation" className="btn userlist text-white" onClick={handleUpdateContact}>Save Contact Information</button>
                    </div>
                </Col>
            </Row>
        </PreviewCard>
    </Block>
  );
}

export default ContactInformation;
