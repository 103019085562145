import React, { useState } from "react";
import {useParams} from "react-router-dom"
import {userData } from "../components/table/TableData";
import Content from "../../layout/content/Content";
import PricingOverlay from "./PricingOverlay";

import { Link } from "react-router-dom";
import {Label,Input, Row, Col } from "reactstrap";
import {
    Block,
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    PreviewCard,
    ReactDataTable,
    Icon,
  } from "../../components/Component";
  import Head from "../../layout/head/Head";
  import {dataTableColumns3, userData1,notification,notificationDataTableColumns, mining ,miningDataTableColumns,transaction,transactionDataTableColumns} from "../components/table/TableData";

const UserViewDetails = () => {
    const id = useParams(); 
    const userId = parseInt(id.userId);
    const user = userData.find(user => user.id === userId);
    const [activeTab, setActiveTab] = useState('personalInfo');
    const [overlayVisible, setOverlayVisible] = useState(false);

    const turnOffOverlay = () => {
        setOverlayVisible(false);
    };

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    if (!user) {
        return <div><h3>User not found</h3></div>;
    }
  return (
    <React.Fragment>
    <Head title="Form Elements" />
    <Content >
        <BlockHead size="lg" className={`d-flex`}>
            <BlockHeadContent>
                <BlockTitle tag="h3" className="fw-normal">
                Edit User/<strong className="text-primary small"></strong>
                </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent className={`ms-auto`}>
                <Link to={"/user-list"} className="btn btn-outline-light bg-white"><em className="icon ni ni-list"></em></Link>
            </BlockHeadContent>
        </BlockHead>
            <PreviewCard>
                {/* <div className="card card-bordered"> */}
		            <div className="card-aside-wrap">
			            <div className="card-content">
                            <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">                 
                                <li className="nav-item">
                                    <Link tag="a" className={`nav-link ${activeTab === 'personalInfo' ? 'active' : ''}`} onClick={() => handleTabChange('personalInfo')} data-bs-toggle="tab" to="#personalInfo"><Icon className="ni ni-user-circle"></Icon><span>Professional Information</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link tag="a" className={`nav-link ${activeTab === 'skillExpertise' ? 'active' : ''}`} onClick={() => handleTabChange('skillExpertise')} data-bs-toggle="tab" to="#skillExpertise"><Icon className="icon ni ni-user-list-fill"></Icon><span>Skills And Expertise</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link tag="a" className={`nav-link ${activeTab === 'experiance' ? 'active' : ''}`} onClick={() => handleTabChange('experiance')} data-bs-toggle="tab" to="#experiance"><Icon className="icon ni ni-account-setting"></Icon><span>Experience</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link tag="a" className={`nav-link ${activeTab === 'webRefernces' ? 'active' : ''}`} onClick={() => handleTabChange('webRefernces')} data-bs-toggle="tab" to="#webRefernces"><Icon className="icon ni ni-globe"></Icon><span>Web References</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link tag="a" className={`nav-link ${activeTab === 'subscriptionInfo' ? 'active' : ''}`} onClick={() => handleTabChange('subscriptionInfo')} data-bs-toggle="tab" to="#subscriptionInfo"><Icon className="icon ni ni-property"></Icon><span>Subscription</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link tag="a"className={`nav-link ${activeTab === 'notifiacationLog' ? 'active' : ''}`} onClick={() => handleTabChange('notifiacationLog')} data-bs-toggle="tab" to="#notifiacationLog"><Icon className="icon ni ni-bell"></Icon><span>Notification</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link tag="a" className={`nav-link ${activeTab === 'miningLog' ? 'active' : ''}`} onClick={() => handleTabChange('miningLog')} data-bs-toggle="tab" to="#miningLog"><Icon className="icon ni ni-network"></Icon><span>Mining</span></Link>
                                </li>
                                <li className="nav-item">
                                    <Link tag="a" className={`nav-link ${activeTab === 'transactionLog' ? 'active' : ''}`} onClick={() => handleTabChange('transactionLog')} data-bs-toggle="tab" to="#transactionLog"><Icon className="icon ni ni-tranx"></Icon><span>Transaction</span></Link>
                                </li>
                                <li className="nav-item nav-item-trigger d-xxl-none">
                                    <Link tag="a" to="#" className="toggle btn btn-icon btn-trigger" data-target="userAside"><Icon className="ni ni-user-list-fill"></Icon></Link>
                                </li>
                            </ul>
				            <div className="card-inner">
					            <div className="tab-content">
						            <div className={`tab-pane ${activeTab === 'personalInfo' ? 'active' : ''}`} id="personalInfo">
							            <div className="nk-block">
                                            <div className="nk-block-head">
                                                <h5 className="title">Professional Information</h5>
                                                <h6 className="title overline-title text-base">Basic Information</h6>									
                                            </div>
                                            <div className="profile-ud-list">
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider"><span className="profile-ud-label">Username</span><span className="profile-ud-value">TempUser2</span></div>
                                                </div>
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider"><span className="profile-ud-label">First Name</span><span className="profile-ud-value">TempUser2</span></div>
                                                </div>
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider"><span className="profile-ud-label">Date of Birth</span><span className="profile-ud-value">10 Aug, 1980</span></div>
                                                </div>
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider"><span className="profile-ud-label">Last Name</span><span className="profile-ud-value"></span></div>
                                                </div>
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider"><span className="profile-ud-label">Mobile Number</span><span className="profile-ud-value">+918877442233</span></div>
                                                </div>
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider"><span className="profile-ud-label">Email Address</span><span className="profile-ud-value">kexal65319@ebuthor.com</span></div>
                                                </div>
                                            </div>
							            </div>
							            <div className="nk-block">
                                            <div className="nk-block-head nk-block-head-line">
                                                <h6 className="title overline-title text-base">Additional Information</h6>
                                            </div>
                                            <div className="profile-ud-list">
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider"><span className="profile-ud-label">Country</span><span className="profile-ud-value">India</span></div>
                                                </div>
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider"><span className="profile-ud-label">State</span><span className="profile-ud-value"></span></div>
                                                </div>
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider"><span className="profile-ud-label">City</span><span className="profile-ud-value"></span></div>
                                                </div>
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider"><span className="profile-ud-label">Short Bio</span><span className="profile-ud-value"></span></div>
                                                </div>
                                            </div>
							            </div>
						            </div>
                                    <div className={`tab-pane ${activeTab === 'skillExpertise' ? 'active' : ''}`} id="skillExpertise">
                                        <div className="nk-block">
                                            <div className="nk-block-head">
                                                <h5 className="title">Skills And Expertise</h5>
                                                <h6 className="title overline-title text-base">Basic Information</h6>									
                                            </div>
                                            <div className="profile-ud-list">
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider"><span className="profile-ud-label">Occupation</span><span className="profile-ud-value"></span></div>
                                                </div>
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider"><span className="profile-ud-label">Job Title</span><span className="profile-ud-value"></span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`tab-pane ${activeTab === 'experiance' ? 'active' : ''}`} id="experiance">
                                        <div className="nk-block">
                                            <div className="nk-block-head">
                                                <h5 className="title">Personal Information</h5>
                                                <h6 className="title overline-title text-base">Basic Information</h6>
                                            </div>
                                            <div className="profile-ud-list">
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider"><span className="profile-ud-label">Company Name</span><span className="profile-ud-value"></span></div>
                                                </div>
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider"><span className="profile-ud-label">Position Title</span><span className="profile-ud-value"></span></div>
                                                </div>
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider"><span className="profile-ud-label">Start Date</span><span className="profile-ud-value">1</span></div>
                                                </div>
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider"><span className="profile-ud-label">End Date</span><span className="profile-ud-value"></span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`tab-pane ${activeTab === 'webRefernces' ? 'active' : ''}`} id="webRefernces">
                                        <div className="nk-block">
                                            <div className="nk-block-head">
                                                <h5 className="title">Personal Information</h5>
                                                <h6 className="title overline-title text-base">Basic Information</h6>
                                            </div>
                                            <div className="profile-ud-list">
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider">
                                                        <span className="profile-ud-label">Facebook</span>
                                                        <span className="profile-ud-value">
                                                            <a href="" target="_blank"></a>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider">
                                                        <span className="profile-ud-label">Twitter</span>
                                                        <span className="profile-ud-value">
                                                            <a href="" target="_blank"> </a>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider">
                                                        <span className="profile-ud-label">LinkedIn</span>
                                                        <span className="profile-ud-value">
                                                            <a href="" target="_blank"></a>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider">
                                                        <span className="profile-ud-label">Pinterest</span>
                                                        <span className="profile-ud-value">
                                                            <a href="" target="_blank"></a>
                                                        </span>												
                                                    </div>
                                                </div>
                                                <div className="profile-ud-item d-none">
                                                    <div className="profile-ud wider">
                                                        <span className="profile-ud-label">Snapchat</span>
                                                        <span className="profile-ud-value">
                                                            <a href="" target="_blank"></a>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="profile-ud-item">
                                                    <div className="profile-ud wider">
                                                        <span className="profile-ud-label">WhatsApp</span>
                                                        <span className="profile-ud-value">
                                                            <a href="whatsapp://send?abid=&amp;text=Hello"></a>
                                                        </span>											
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>							
                                    <div className={`tab-pane ${activeTab === 'subscriptionInfo' ? 'active' : ''}`} id="subscriptionInfo">
                                        <Block size="lg">
                                            <BlockHead className={`d-flex`}>
                                                <BlockHeadContent>
                                                    <BlockTitle tag="h5">Subscription Information</BlockTitle>
                                                    <BlockTitle tag="h6" className={`overline-title text-base`}>Users current subscription</BlockTitle>
                                                </BlockHeadContent>
                                                <BlockHeadContent className={`ms-auto`}>
                                                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#freeSubscription" onClick={() => setOverlayVisible(true)} >
                                                        Free Subscription
                                                    </button>
                                                    {overlayVisible && <PricingOverlay turnOffOverlay={turnOffOverlay} />}
                                                </BlockHeadContent>
                                            </BlockHead>
                                            <PreviewCard>
                                                <ReactDataTable data={userData1} columns={dataTableColumns3} 
                                                expandableRows pagination actions />
                                            </PreviewCard>
                                        </Block>
                                    </div>
                                    <div className={`tab-pane ${activeTab === 'notifiacationLog' ? 'active' : ''}`} id="notifiacationLog">
                                        <Block size="lg">
                                            <BlockHead>
                                                <BlockHeadContent>
                                                    <BlockTitle tag="h5">Notification Information</BlockTitle>
                                                    <BlockTitle tag="h6" className={`overline-title text-base`}>Users notification log</BlockTitle>
                                                </BlockHeadContent>
                                            </BlockHead>
                                            <PreviewCard>
                                                <ReactDataTable data={notification} columns={notificationDataTableColumns} 
                                                expandableRows pagination actions />
                                            </PreviewCard>
                                        </Block>
                                    </div>
                                    <div className={`tab-pane ${activeTab === 'miningLog' ? 'active' : ''}`} id="miningLog">
                                        <Block size="lg">
                                            <BlockHead>
                                                <BlockHeadContent>
                                                    <BlockTitle tag="h5">Mining Information</BlockTitle>
                                                    <BlockTitle tag="h6" className={`overline-title text-base`}>Users mining log</BlockTitle>
                                                </BlockHeadContent>
                                            </BlockHead>
                                            <PreviewCard>
                                                <ReactDataTable data={mining} columns={miningDataTableColumns} 
                                                expandableRows pagination actions />
                                            </PreviewCard>
                                        </Block>
                                    </div>	
                                    <div className={`tab-pane ${activeTab === 'transactionLog' ? 'active' : ''}`} id="transactionLog">
                                        <Block size="lg">
                                            <BlockHead>
                                                <BlockHeadContent>
                                                    <BlockTitle tag="h5">Transaction Information</BlockTitle>
                                                    <BlockTitle tag="h6" className={`overline-title text-base`}>Users transaction log</BlockTitle>
                                                </BlockHeadContent>
                                            </BlockHead>
                                            <PreviewCard>
                                                <ReactDataTable data={transaction} columns={transactionDataTableColumns} 
                                                expandableRows pagination actions />
                                            </PreviewCard>
                                        </Block>
                                    </div>
					            </div>
				            </div>
			            </div>
                        <div className="card-aside card-aside-right user-aside toggle-slide toggle-slide-right toggle-break-xxl toggle-screen-xxl" data-content="userAside" data-toggle-screen="xxl" data-toggle-overlay="true" data-toggle-body="true">
                            <div className="card-inner-group" data-simplebar="init">
                                <div className="simplebar-wrapper" style={{margin: '0px'}}>
                                    <div className="simplebar-height-auto-observer-wrapper">
                                        <div className="simplebar-height-auto-observer"></div>
                                    </div>
                                    <div className="simplebar-mask">
                                        <div className="simplebar-offset" style={{right: '0px', bottom: '0px'}}>
                                            <div className="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content" style={{height: 'auto', overflow: 'hidden'}}>
                                                <div className="simplebar-content" style={{padding: '0px'}}>
                                                    <div className="card-inner">
                                                        <div className="user-card user-card-s2">
                                                            <div className="user-avatar lg bg-primary">
                                                                <img src="https://demo.silocloud.org/assets/images/image-placeholder.png" alt="TempUser2's profile image" className="w-100 h-100 round rounded-circle"/>
                                                            </div>
                                                            <div className="user-info">
                                                                <div className="badge bg-outline-light rounded-pill ucap">Role : Normal</div>
                                                                <h5>TempUser2 </h5>
                                                                <span className="sub-text"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-inner card-inner-sm">
                                                        <div className="qr code text-center">
                                                            <img src="data:data:png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhAQMAAAAEfUA5AAAABlBMVEX///8AAABVwtN+AAAACXBIWXMAAA7EAAAOxAGVKw4bAAACfUlEQVRYhe2YTW6rQBCEG7FgyRHmJnAxSyBxMXyTOYKXLCx3qmrAid8qu3qLjBRE+LA09E9190T8rd+vOTOP+bHU6B63yORlH/Hw5aQT/o45nyW6XNvdMY1rAz46Zj067BF3AN0jSsS45eGnM7abe9zKcM9nHP8J5QVeXQM06797dlD6d4oeUddci/den943UOXCNG714/KRKQaqhTQoCSPm8IIRh2/gozeEWfTMBYVexLDH8n7FQ5EGZaBD4VXYtMut8r2SZvokeJbrgpfvjz61exudYEnk5ZYxP/RvXylptK6Ryr8ThQK3ZWApwstraRnqouel7bRAbaEbybtw0qBQxPhEBtC/dC2eZT0zxUQnJmdHvZ+Ql1grzcmEcNJgNt7R53DPISNiu7dyfZGHthaCG2cLNiR1g4ZNK6UlcdfXphZUfrSIfXop1UJ5qVhTs5O05Fm7TTQo+uya0VYszAD1FuulZiY6w6Eoi31F6EWRJVskvpw0CCgZkn9IWs+N31oVt1G6UQEHIK9y95CRbwX20AVS3/JSvQXij/U8rDSoXIk9w8nRzDnQ08PupDKd9J7yeo2MmDpeTsqOQuKRHMmCQqZfvL/IQtF9FfanraHHgyVZDcJLg15NTdbKVT7AhPa2pIdO0ojW52Sby1okWinqNGONM2Kb91Ul3VSts45FNF4j6pgaedrURbVmqUWyU1X8RV/f3rdQzfsdGq8jzlmo6juuqc1DdTbC0y0W66LGkDb9eWLjoDpz47NTPFSP8ur5nXTmWSkKOGehTUUzz9rtpMECfr8GxdYYTlYq/7Id3EOD4pbqn6+64KHKBTaG7biNBzgMvfTSv/Xb9QVNAe08MW/jiwAAAABJRU5ErkJggg==" alt="TempUser2's qr code" className="w-100px h-100px"/>                
                                                        </div>
                                                        <ul className="btn-toolbar justify-center gx-1 d-none">
                                                            <li><a href="#" className="btn btn-trigger btn-icon"><em className="icon ni ni-shield-off"></em></a></li>
                                                            <li><a href="#" className="btn btn-trigger btn-icon"><em className="icon ni ni-mail"></em></a></li>
                                                            <li><a href="#" className="btn btn-trigger btn-icon"><em className="icon ni ni-download-cloud"></em></a></li>
                                                            <li><a href="#" className="btn btn-trigger btn-icon"><em className="icon ni ni-bookmark"></em></a></li>
                                                            <li><a href="#" className="btn btn-trigger btn-icon text-danger"><em className="icon ni ni-na"></em></a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="card-inner">
                                                        <div className="overline-title-alt mb-2">In Account</div>
                                                        <div className="profile-balance">
                                                            <div className="profile-balance-group gx-4">
                                                                <div className="profile-balance-sub">
                                                                    <div className="profile-balance-amount">
                                                                        <div className="number">0</div>
                                                                    </div>
                                                                    <div className="profile-balance-subtitle">Token Balance</div>
                                                                </div>
                                                                <div className="profile-balance-sub d-none">
                                                                    <span className="profile-balance-plus text-soft"><em className="icon ni ni-plus"></em></span>
                                                                    <div className="profile-balance-amount">
                                                                        <div className="number">1,643.76</div>
                                                                    </div>
                                                                    <div className="profile-balance-subtitle">Profit Earned</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-inner d-none">
                                                        <Row className="text-center">
                                                            <div className="col-4">
                                                                <div className="profile-stats">
                                                                    <span className="amount">0</span>
                                                                    <span className="sub-text">View Count</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="profile-stats">
                                                                    <span className="amount">0</span>
                                                                    <span className="sub-text">Complete</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-4">
                                                                <div className="profile-stats">
                                                                    <span className="amount">0</span>
                                                                    <span className="sub-text">Progress</span>
                                                                </div>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                    <div className="card-inner">
                                                        <h6 className="overline-title-alt mb-2">Additional</h6>
                                                        <Row className="g-3">
                                                            <div className="col-6">
                                                                <span className="sub-text">User ID:</span>
                                                                <span>5265</span>
                                                            </div>
                                                            <div className="col-6">
                                                                <span className="sub-text">Last Seen:</span>
                                                                <span>2024-02-27 01:16:20</span>
                                                            </div>
                                                            <div className="col-6">
                                                                <span className="sub-text">Status:</span>
                                                                <span className="lead-text text-danger">Not Verified</span>
                                                            </div>
                                                            <div className="col-6">
                                                                <span className="sub-text">Register At:</span>
                                                                <span>2024-02-27 01:16:20</span>
                                                            </div>
                                                        </Row>
                                                    </div>					
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="simplebar-placeholder" style={{width: 'auto', height: '819px'}}></div>
                                </div>
                                <div className="simplebar-track simplebar-horizontal" style={{visibility: 'hidden'}}>
                                    <div className="simplebar-scrollbar" style={{width: '0px', display: 'none'}}></div>
                                </div>
                                <div className="simplebar-track simplebar-vertical" style={{visibility: 'hidden'}}>
                                    <div className="simplebar-scrollbar" style={{height: '0px', display: 'none'}}></div>
                                </div>
                            </div>
                        </div>
		            </div>
	            {/* </div> */}
            </PreviewCard>
        </Content>
    </React.Fragment>
  );
}

export default UserViewDetails;
