import { useState } from "react";

const useShowHidePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const togglePassword = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else if (field === "re-password") {
      setShowRePassword(!showRePassword);
    }
  };

  return {
    showPassword,
    showRePassword,
    togglePassword
  };
};

export default useShowHidePassword;
