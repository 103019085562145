import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Icon } from "../../components/Component";
import { getCookie } from "../../utils/Utils";
import { toast, useToast } from "react-toastify";
import Swal from "sweetalert2";
import "../../assets/css/AddUser.css";
import { Spinner, Tooltip } from "reactstrap";
import { useNavigate } from "react-router";
function NewsAction({ row, onDelete }) {
  const [editTooltipOpen, setEditTooltipOpen] = useState(false);
  const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false);
  const [showTooltipOpen, setShowTooltipOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [deleteItem, setDeleteItem] = useState(true);
  const navigate = useNavigate();

  const toggleEditTooltip = () => {
    setEditTooltipOpen(!editTooltipOpen);
  };

  const toggleDeleteTooltip = () => {
    setDeleteTooltipOpen(!deleteTooltipOpen);
  };

  const toggleShowTooltip = () => {
    setShowTooltipOpen(!showTooltipOpen);
  };
  
  const handleDeleteConfirmation = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
      }
    });
  };

  const handleDelete = async (id) => {
    const authToken = getCookie("authToken");
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/blog/delete-blog`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authToken: authToken,
        },
        body: JSON.stringify({ blog_id: id }),
      });

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.status) {
          toast.success(responseData.message);
          onDelete(id);
        } else {
          toast.error(responseData.message);
        }
      } else {
        console.error("Delete request failed:", response.statusText);
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error("Delete error:", error);
    } finally {
      setLoading(false);
    }
    setDeleteItem(true);
  };


  return (
    <div className="ms-5 action-column">
      {loading && (
        <div className="loader-spinner-custom d-flex justify-content-center align-items-center">
          <Spinner color="dark" />
        </div>
      )}
      <div className="d-flex me-4">
        <Link to={`/edit/${row.id}`} className="btn btn-trigger btn-icon" id={`edit-icon-${row.id}`} onMouseEnter={toggleEditTooltip} onMouseLeave={toggleEditTooltip}>
          <Icon
            className="ni ni-edit-fill"></Icon>
        </Link>
        <Tooltip placement="bottom" isOpen={editTooltipOpen} target={`edit-icon-${row.id}`} toggle={toggleEditTooltip}>
          Edit
        </Tooltip>
        <Link className="btn btn-trigger btn-icon btn-user-delete" onClick={() => handleDeleteConfirmation(row.id)} id={`delete-icon-${row.id}`} onMouseEnter={toggleDeleteTooltip} onMouseLeave={toggleDeleteTooltip}>
          <Icon
            className="ni ni-trash-fill"></Icon>
        </Link>
        <Tooltip
          placement="bottom"
          isOpen={deleteTooltipOpen}
          target={`delete-icon-${row.id}`}
          toggle={toggleDeleteTooltip}
        >
          Delete
        </Tooltip>
        <Link to={`${process.env.REACT_APP_BLOG_URL}${row.id}`} className="btn btn-trigger btn-icon btn-user-delete" target="_blank" id={`show-icon-${row.id}`} onMouseEnter={toggleShowTooltip} onMouseLeave={toggleShowTooltip}>
          <Icon
            className="ni ni-eye-fill"></Icon>
        </Link>
        <Tooltip placement="bottom" isOpen={showTooltipOpen} target={`show-icon-${row.id}`} toggle={toggleShowTooltip}>
          Show
        </Tooltip>
      </div>
    </div>
  );
}

export default NewsAction;
