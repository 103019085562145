import React, { useState } from "react";
import Content from "../../layout/content/Content";
import "../../assets/css/AddUser.css";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  ReactDataTable,
  Icon,
} from "../../components/Component";
import makeAnimated from "react-select/animated";
import Head from "../../layout/head/Head";
import {recentusers,recentUserDataTableColumns } from "../components/table/TableData";
import { Link } from "react-router-dom";
const RecentUsers = () => {
  const [file, setFile] = useState("");

  return (
    <React.Fragment>
      <Head title="Basic Tables" />
      <Content page="component">
        <BlockHead size="lg" wide="sm">
        </BlockHead>

        <Block size="lg" className="recent-users-list-table">
          <BlockHead className={`d-flex`}>
            <BlockHeadContent>
              <BlockTitle tag="h3">Recent User List
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent className={`ms-auto`}>
              <Link data-bs-toggle="tooltip" data-bs-placement="top" title=""  data-bs-original-title="Broadcast Mail/SMS" >
                <button className="btn btn-primary mx-1">
                  <Icon className="ni ni-spark-fill"></Icon>
                </button>
              </Link>
              <Link tag="a">
                <button className="btn btn-primary mx-1">
                  <Icon className="ni ni-mail"></Icon>
                </button>
              </Link>
              <Link tag="a">
                <button className="btn btn-primary mx-1">User Location</button>
              </Link>
              <Link tag="a">
                <button className="btn btn-primary mx-1">User Logs</button>
              </Link>
              <Link tag="a">
                <button className="btn btn-primary mx-1"> Manage User Roles</button>
              </Link>
              <Link to={"create"} className="btn btn-outline-light bg-white mx-1 tooltip_toggler" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Add User">
                <Icon className="ni ni-plus"></Icon>
              </Link>
              <Link to={"/user-list"} tag="a" className="btn btn-outline-light bg-white mx-1 tooltip_toggler" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Recent user list">
                <Icon className="ni ni-list"></Icon>
              </Link>
            </BlockHeadContent>
          </BlockHead>

          <PreviewCard>
            <ReactDataTable data={recentusers} columns={recentUserDataTableColumns} 
               expandableRows pagination actions />
          </PreviewCard>
        </Block>

      </Content>
    </React.Fragment>
  );
};

export default RecentUsers;