import React, { useState, useEffect, useRef } from "react";
import Content from "../../layout/content/Content";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Label, Input, Row, Col, Button, Spinner } from "reactstrap";
import "../../assets/css/AddUser.css";
import { Block, BlockHead, BlockHeadContent, BlockTitle, PreviewCard, Icon, RSelect } from "../../components/Component";
import Head from "../../layout/head/Head";
import { getCookie } from "../../utils/Utils";
import { toast } from "react-toastify";
import { QuillComponent } from "../../components/partials/rich-editor/QuillComponent";
import { useQuill } from "react-quilljs";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import videoPlaceholderImage from "../../../src/images/video_placeholder.png";
import imagePlaceholderImage from "../../../src/images/image_placeholder.png";
import { useNavigate } from "react-router";


//custom description
import { convertFromRaw, EditorState,convertToRaw,ContentState, ContentBlock, genKey} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CustomDescription from "./CustomDescription";
import localization from "../../../src/config/localization";
import toolbar from "../../../src/config/toolbar";
import "../../assets/css/description.css";

const InputTags = ({ label, placeholder, tags, setTags }) => {
  const [newTag, setNewTag] = useState("");

  const handleTagChange = (e) => {
    setNewTag(e.target.value);
  };

  const handleTagKeyDown = (e) => {
    if (e.key === "Enter" && newTag.trim() !== "") {
      e.preventDefault();
      setTags([...tags, newTag.trim()]);
      setNewTag("");
    }
  };

  const handleTagRemove = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className="form-group">
      <label className="form-label" htmlFor="tag">
        {label} <small>(Press ENTER to add to list)</small>
      </label>
      <div className="form-control-wrap">
        <div
          className="bootstrap-tagsinput"
          onKeyDown={handleTagKeyDown}
          tabIndex="0"
          style={{ background: "#28202024", border: "2px solid #332f2f80", height: "91px", overflowY: "scroll" }}
        >
          {tags.map((tag, index) => (
            <span key={index} className="badge bg-primary me-1 align-items-center">
              {tag}
              <button
                type="button"
                className="btn-close badge-close fs-16px"
                onClick={() => handleTagRemove(index)}
                aria-label="Close"
              ></button>
            </span>
          ))}
          <input
            data-role="tagsinput"
            type="text"
            placeholder={placeholder}
            value={newTag}
            onChange={handleTagChange}
            className="ms-2"
            style={{
              border: "none",
              boxShadow: "none",
              outline: "none",
              backgroundColor: "transparent",
              padding: "0",
              margin: "0",
              width: "auto !important",
              maxWidth: "inherit",
            }}
          />
        </div>
      </div>
    </div>
  );
};

const CreateNews = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [newtags, setNewsTags] = useState([]);
  const [newsType, setNewsType] = useState("default_option");
  const [showCategory, setShowCategory] = useState(false);
  const [videoPreview, setVideoPreview] = useState("");
  const [description, setDescription] = useState("");
  const [quillurl, setUrl] = useState("");
  //audio file
  const [defaultFiles, setDefaultFiles] = useState("");
  const [files, setFiles] = useState([]);
  //new description
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [contentState, setContentState] = useState(null);

  const [audioFile, setAudioFile] = useState(null);
  const [audioUrl, setAudioUrl] = useState("");
  const [audioFilename, setAudioFilename] = useState("");
  
  const handleAudioChange = (e) => {
    const file = e.target.files[0];
    setAudioFile(file);

    if (file) {
      const url = URL.createObjectURL(file);
      setAudioUrl(url);
    }
  };

  const extractFilenameFromUrl = (url) => {
    const filename = url.match(/[^/]+(?=\?)/)[0];
    return filename;
  };

  const handleContentStateChange = (contentState) => {
    setContentState(contentState); 
  
    if (contentState && typeof contentState.getBlockMap === 'function') {
      const blocks = contentState.getBlockMap().toArray(); 
      const content = blocks.map(block => block.getText()).join('\n'); 
      setFormdata(prevFormdata => ({
        ...prevFormdata,
        description: content
      }));
  
      const contentStateWithBlocks = ContentState.createFromBlockArray(blocks);
      const editorState = EditorState.createWithContent(contentStateWithBlocks);
      setEditorState(editorState); 
    }
  };
  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };


  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ align: [] }],
      ["clean"],
    ],
  };
  const placeholder = "Enter Your Text";
  const formats = [
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "align",
    "list",
    "indent",
    "size",
    "header",
    "link",
    "color",
    "background",
    "script",
  ];
  const { quill, quillRef } = useQuill({ modules, formats, placeholder });

  useEffect(() => {
    if (quill) {
      quill.on("text-change", handleUrlChange);
    }
    return () => {
      if (quill) {
        quill.off("text-change", handleUrlChange);
      }
    };
  }, [quill]);
  
  const handleUrlChange = (event) => {
    let newValue;
    if (quill) {
      const text = quill.container.innerText.trim();
      setUrl(text);
    } else {
      newValue = event.target.value.trim();
      setUrl(newValue);
    }
  };
  

  const handleDescriptionChange = (value) => {
    setDescription(value);
    setFormdata((prevFormdata) => ({
      ...prevFormdata,
      description: value,
    }));
  };

  const [blogImg, setBlogImg] = useState({
    file: null,
    preview: "https://placehold.co/199x182",
  });

  const [blogDetailImg, setBlogDetailImg] = useState({
    file: null,
    preview: "https://placehold.co/199x182",
  });

  const [video, setVideo] = useState(null);

  const handleDropChange = (acceptedFiles, type) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      if (type === "blog_img") {
        setBlogImg({
          file: file,
          preview: reader.result,
        });
      } else if (type === "blog_detail_img") {
        setBlogDetailImg({
          file: file,
          preview: reader.result,
        });
      } else if (type === "video") {
        setVideo({
          file: file,
          preview: reader.result,
        });
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const [formdata, setFormdata] = useState({
    title: "",
    description: "",
    author: "",
    category:"TECH TRENDS",
    type:"default_option",
  });

  useEffect(() => {
    if (formdata.description) {
      const contentState = convertFromRaw(JSON.parse(formdata.description));
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [formdata.description]);

  const handleBeforeInput = (input) => {
    if (this.props.maxLength) {
      if (draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())).length >=3000) {
        return 'handled';
      }
    }
  };

  const fetchBlogData = async () => {
    if (!userId) return;

    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/public/get-blog`;
      const formData = new FormData();

      formData.append("id", userId);
      const response = await fetch(apiUrl, {
        headers: {
          authToken: getCookie("authToken"),
        },
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        if (response.status) {
          const blog = data.data.blogs;
          const tagsArray = blog.tags ? JSON.parse(blog.tags) : [];

          const blogImgPreview = blog.blog_image ? blog.blog_image : "https://placehold.co/199x182";
          const blogDetailImgPreview = blog.blog_detail_image ? blog.blog_detail_image : "https://placehold.co/199x182";

          setFormdata({
            title: blog.title || "",
            description: blog.description || "",
            author: blog.author || "",
            news_for: blog.for_whom || "default_option",
            type: blog.type || "default_option",
            category: blog.categories || "",
            tags: tagsArray,
            blogImg: {
              file: null,
              //   preview: blog.blog_image || "https://placehold.co/199x182",
              preview: blogImgPreview,

            },
            blogDetailImg: {
              file: null,
              //   preview: blog.blog_detail_image || "https://placehold.co/199x182",
              preview: blogDetailImgPreview,
            },
            video: blog.blog_video ? { file: null, preview: blog.blog_video } : null,
          });

          setBlogImg({
            file: null,
            // preview: blog.blog_image,
            preview: blogImgPreview,
          });
          setBlogDetailImg({
            file: null,
            // preview: blog.blog_detail_image,
            preview: blogDetailImgPreview,
          });
          setVideo({
            file: null,
            preview: blog.blog_video,
          });

          setDescription(blog.description || "");
          setNewsType(blog.type || "default_option");
          setShowCategory(blog.type === "1");
          setNewsTags(tagsArray);
          // if (blogAudioPreview) {
          //   setAudioUrl(blogAudioPreview);
          // }

          if (blog.blog_audio) {
            setAudioUrl(blog.blog_audio);
          }
          if (blog.blog_audio) {
            const filename = extractFilenameFromUrl(blog.blog_audio);
            setAudioFilename(filename);
          }
          if (blog.blog_audio) {
            const filename = extractFilenameFromUrl(blog.blog_audio);
            setAudioFilename(filename);
            setAudioFile(blog.blog_audio);
          }
        } else {
          console.error("Error fetching blog data", response.statusText);
        }
      } else {
        console.error("Error fetching blog data", response.statusText);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  useEffect(() => {
    fetchBlogData();
  }, [userId]);

  const handleTypeChange = (e) => {
    const selectedType = e.target.value;
    setNewsType(selectedType);
    setShowCategory(selectedType === "1");
  };

  useEffect(() => {
    setFormdata((prev) => ({
      ...prev,
      tags: newtags.join(","),
    }));
  }, [newtags]);
  const [loading, setLoading] = useState(false);
  const handleSave = async (e) => {
    e.preventDefault();

     // Check if description is empty
     if (formdata.title == '') {
      toast.error("Title field is required");
      return;
    }
    if (formdata.type === 'default_option') {
      toast.error("Please select a valid type");
      return;
    }
    if (!editorState.getCurrentContent().hasText()) {
      toast.error("Description field is required");
      return;
    }
    const descriptionLength = editorState.getCurrentContent().getPlainText('').length;
    if (descriptionLength >= 3000) {
      toast.error("Description exceed 3000 characters");
      return;
    } 
    const formData = new FormData();

    if (userId) {
      formData.append("blog_id", userId);
    }

    // formData.append("for_whom", formdata.news_for);
    formData.append("title", formdata.title);
    const descriptionContent = convertToRaw(editorState.getCurrentContent());
    formData.append("type", formdata.type);
    formData.append("description", JSON.stringify(descriptionContent));
    formData.append("author", formdata.author);
    formData.append("categories", formdata.category);
    newtags.forEach((tag, index) => {
      formData.append(`tags[${index}]`, tag);
    });

    if (blogImg.file) {
      formData.append("blog_image", blogImg.file);
    }
    if (blogDetailImg.file) {
      formData.append("blog_detail_image", blogDetailImg.file);
    }

    if (audioFile) {
      formData.append("blog_audio", audioFile);
    }

    if (video && video.file !== null) {
      const blob = new Blob([video.file], { type: "video/*" });
      formData.append("blog_video", blob);
    }
    let apiUrl = `${process.env.REACT_APP_API_URL}api/v1/blog/add-blog`;

    if (userId) {
      apiUrl = `${process.env.REACT_APP_API_URL}api/v1/blog/update-blog`;
    }

    try {
      setLoading(true);
      const response = await fetch(apiUrl, {
        headers: {
          authToken: getCookie("authToken"),
        },
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.status) {
          toast.success(data.message);
          setTimeout(() => {
            // window.location.href = "/";
            navigate("/admin-manage-cms/admin-news-list");
          }, 3000);
        } else {
          if (data?.errors) {
            toast.error(data?.errors[0]);
          } else {
            toast.error(data.message);
          }
        }
      } else {
        console.error("Error adding blog", response.statusText);
        toast.error("Error adding blog");
      }
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Head title="Form Elements" />
      {loading && (
        <div className="loader-spinner-custom d-flex justify-content-center align-items-center">
          <Spinner color="dark" />
        </div>
      )}
      <Content>
        <BlockHead size="lg" className={`d-flex`}>
          <BlockHeadContent>
            <BlockTitle tag="h4">Create Blog</BlockTitle>
          </BlockHeadContent>
          <BlockHeadContent className={`ms-auto`}>
            <Link to={"/admin-manage-cms/admin-news-list"} className="btn btn-outline-light text-white mx-1 tooltip_toggler userlist" title="Blog List">
              <Icon className="ni ni-list me-2"></Icon> Blog List
            </Link>
          </BlockHeadContent>
        </BlockHead>
        <Block size="lg">
          <PreviewCard>
            <form id="admin_news_form" method="post" encType="multipart/formdata" noValidate="novalidate">
              <Row className="gy-4">
                <Col sm="6">
                  <div className="form-group">
                    <Label htmlFor="title" className="form-label">
                      {" "}
                      Title <span className="text-danger"> *</span>
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        id="title"
                        name="title"
                        placeholder="Enter title here..."
                        type="text"
                        value={formdata.title}
                        onChange={(e) => setFormdata({ ...formdata, title: e.target.value })}
                      />
                    </div>
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group">
                    <Label htmlFor="type" className="form-label">
                      {" "}
                      Type <span className="text-danger"> *</span>
                    </Label>
                    <div className="form-control-wrap">
                      <div className="form-control-select">
                        <Input
                          type="select"
                          name="type"
                          id="type"
                          onChange={(e) => {
                            setFormdata({ ...formdata, type: e.target.value });
                            handleTypeChange(e);
                          }}
                          // value={formdata.type}
                          value={formdata.type || "default_option"}
                        >
                          <option value="default_option">Default Option</option>
                          {/* <option value="0">News</option> */}
                          <option value="1">Blog</option>
                        </Input>
                      </div>
                    </div>
                  </div>
                </Col>
                {/* <Col sm="6" className="">
                  <InputTags
                    label="Tags"
                    placeholder="Add Tags"
                    tags={newtags}
                    setTags={setNewsTags}
                    name="tags"
                    id="tags"
                  />
                </Col> */}
                <Col sm="6">
                  <div className={`${showCategory ? "" : "d-none"} form-group`}>
                    <Label htmlFor="Catagory" className="form-label">
                      Category<span className="text-danger"> *</span>
                    </Label>
                    <div className="form-control-wrap">
                      <div className="form-control-select">
                        <Input
                          type="select"
                          name="Catagory"
                          id="Catagory"
                          onChange={(e) => {
                            setFormdata({ ...formdata, category: e.target.value });
                          }}
                          value={formdata.category || "TECH TRENDS"}
                        >
                          <option value="TECH TRENDS">TECH TRENDS</option>
                          <option value="ARTIFICIAL INTELLIGENCE">ARTIFICIAL INTELLIGENCE</option>
                          <option value="PRODUCT REVIEWS">PRODUCT REVIEWS</option>
                          <option value="CLOUD COMPUTING">CLOUD COMPUTING</option>
                          <option value="TECH NEWS">TECH NEWS</option>
                          <option value="TECH CONFERENCES">TECH CONFERENCES</option>
                        </Input>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <Label htmlFor="author" className="form-label">
                      Author
                    </Label>
                    <div className="form-control-wrap">
                      <Input
                        name="author"
                        id="author"
                        placeholder="Enter author name"
                        type="text"
                        value={formdata.author}
                        onChange={(e) => setFormdata({ ...formdata, author: e.target.value })}
                      />
                    </div>
                  </div>
                  <Row className="justify-content-center">
                    <Col sm="4" className="">
                      <div className="form-group">
                        <div className="text-center">
                          <Label className="form-label">Blog Image</Label>
                          <div className="form-control-wrap">
                            <div className="form-file d-flex justify-content-center" style={{height:'150px'}}>
                              <img
                                src={blogImg.preview}
                                id="newsimg"
                                alt="Profile"
                                style={{ width: "200px", height: '-webkit-fill-available', objectFit: 'cover' }}
                              />
                              <Input
                                name="image"
                                id="image"
                                accept="image/*"
                                className="profile-input1 btn btn-primary"
                                type="file"
                                onChange={(e) => handleDropChange(e.target.files, "blog_img")}
                                style={{
                                  cursor: "pointer",
                                  display: "block",
                                  height: "100px",
                                  left: "0",
                                  opacity: "0 !important",
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  width: "300px",
                                  height: "250px",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm="4" className="">
                      <div className="form-group">
                        <div className="text-center">
                          <Label className="form-label">Blog Detail Image</Label>
                          <div className="form-control-wrap">
                            <div className="form-file d-flex justify-content-center" style={{height:'150px'}}>
                              <img
                                src={blogDetailImg.preview}
                                id="newsimg1"
                                alt="Profile"
                                style={{ width: "200px", height: '-webkit-fill-available', objectFit: 'cover' }}
                              />
                              <Input
                                name="blog_detail_img"
                                id="blog_detail_img"
                                accept="image/*"
                                className="profile-input1 btn btn-primary"
                                type="file"
                                onChange={(e) => handleDropChange(e.target.files, "blog_detail_img")}
                                style={{
                                  cursor: "pointer",
                                  display: "block",
                                  height: "100px",
                                  left: "0",
                                  opacity: "0 !important",
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  width: "300px",
                                  height: "250px",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {/* <Col sm="4" className="mb-5">
                      <div className="form-group">
                        <div className="form-group text-center">
                          <Label className="form-label">Blog Video</Label>
                          <div className="form-control-wrap">
                            <div className="form-file d-flex justify-content-center">
                              {video ? (
                                <video
                                  src={video.preview}
                                  id="blogVideoPreview"
                                  alt="Video Preview"
                                  controls
                                  style={{ width: "200px", height: "145px" }}
                                ></video>
                              ) : (
                                <img
                                  src={videoPlaceholderImage}
                                  id="videoPlaceholder"
                                  className=""
                                  alt="Video Placeholder"
                                  style={{ width: "200px", height: "145px", objectFit: "cover" }}
                                />
                              )}

                              <Input
                                name="blog_detail_video"
                                id="blog_detail_video"
                                accept="video/*"
                                className="profile-input1 btn btn-primary"
                                type="file"
                                onChange={(e) => handleDropChange(e.target.files, "video")}
                                style={{
                                  cursor: "pointer",
                                  display: "block",
                                  height: "100px",
                                  left: "0",
                                  opacity: "0 !important",
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  width: "300px",
                                  height: "250px",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                </Col>
                <Col sm="6">
                  <div className="form-group">
                    <Label htmlFor="description" className="form-label">
                      Description <span className="text-danger"> *</span>
                    </Label>
                    {/* <div className="form-control-wrap">
                      <div style={{ width: "100%", height: "100%" }}>
                        <div ref={quillRef} onChange={handleUrlChange} />
                      </div>
                    </div> */}
                    <Editor
                    className="descriptionEditor"
                      editorState={editorState}
                      toolbarClassName="editor-toolbar"
                      wrapperClassName="editor-wrapper"
                      editorClassName="editor"
                      onEditorStateChange={handleEditorStateChange}
                      onContentStateChange={handleContentStateChange}
                      toolbar={toolbar}
                      localization={localization}
                      spellCheck
                      toolbarCustomButtons={[<CustomDescription />]}
                    />
                    
                  </div>
                </Col>
                <Col sm="6">
                  <div className="form-group">
                    <label className="form-label">Blog Audio</label>
                    <div className="form-control-wrap">
                      <div className="form-file">
                        <Input
                          type="file"
                          id="blog_audio"
                          name="blog_audio"
                          accept=".mp3"
                          onChange={handleAudioChange}
                        />
                      </div>
                    </div>
                    {audioUrl && (
                      <div className="">
                          <div className="d-flex">
                            <p className="me-2 fw-bold">Existing File: </p>
                            <p>{audioFilename}</p>
                          </div>
                          <div>
                            <audio controls>
                              <source src={audioUrl} type="audio/mpeg" />
                              Your browser does not support the audio element.
                            </audio>
                          </div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm="12" className="">
                  <div className="text-center mt-5 mb-5">
                    <button type="submit" className="btn userlist text-white" id="save_admin_news" onClick={handleSave}>
                      {" "}
                      Save
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default CreateNews;
