import React, { useState } from "react";
import Content from "../../layout/content/Content";
import "../../assets/css/AddUser.css";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  ReactDataTable,
  Icon,
} from "../../components/Component";
import Head from "../../layout/head/Head";
import { Link } from "react-router-dom";
import {  Label, Input, Row, Col } from "reactstrap";
const UserRoles = () => {
  const [file, setFile] = useState("");

  return (
    <React.Fragment>
      <Head title="Basic Tables" />
      <Content page="component">
        <Block size="lg">
          <BlockHead ClassName={`d-flex`}>
            <BlockHeadContent>
              <BlockTitle tag="h3">Role Management
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent ClassName={`ms-auto`}>
              <Link to={"/user-list"} ClassName="btn btn-outline-light bg-white mx-1 tooltip_toggler" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="User List">
                <Icon className="ni ni-list"></Icon>
              </Link>
            </BlockHeadContent>
          </BlockHead>

          <PreviewCard>
            <div ClassName="table-responsive">
                 <table ClassName="table table-striped table-bordered" style={{width:'120%'}} id="role_tbl">
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Menu</th>
                            <th>Admin</th>
                            <th>Services Agent</th>
                            <th>Accounting Manager</th>
                            <th>Sales Manager</th>
                            <th>Call Center Staff</th>
                            <th>Logistics Manager</th>
                            <th>Developer</th>
                            <th>Miner</th>
                        </tr>
                    </thead>
                    <tbody id="role-tbody">    
                        {/* <tr>
                            <td><Input type="text" name="menu_id_1" id="menu_id_1" ClassName="menu_id" value="1" hidden=""/></td>
                            <td> <Input type="hidden" id="menuId" name="menuId" value="1"/> Financial Dashboard</td>
                            <td className="d-flex">
                                <span className="ms-1" data-toggle="tooltip" title="" data-bs-original-title="View" aria-label="View" aria-describedby="">
                                    <div ClassName="custom-control custom-checkbox">
                                        <Input type="checkbox" ClassName="custom-control-input cloud_menu" data-id="1" data-key="view" data-col="is_admin" id="is_admin_view_1"/> 
                                        <label ClassName="custom-control-label" for="is_admin_view_1"></label> 
                                    </div>
                                </span>
                                <span className="ms-1" data-toggle="tooltip" title="" data-bs-original-title="Add" aria-label="Add" aria-describedby="">
                                    <div ClassName="custom-control custom-checkbox">
                                        <Input type="checkbox" ClassName="custom-control-input cloud_menu" data-id="1" data-key="add" data-col="is_admin" id="is_admin_add_1"/> 
                                        <label ClassName="custom-control-label" for="is_admin_add_1"></label> 
                                    </div>
                                </span>
                                <span  className="ms-1" data-toggle="tooltip" title="" data-bs-original-title="Delete" aria-label="Delete" aria-describedby="">
                                    <div ClassName="custom-control custom-checkbox">
                                        <Input type="checkbox" ClassName="custom-control-input cloud_menu" data-id="1" data-key="delete" data-col="is_admin" id="is_admin_delete_1"/> 
                                        <label ClassName="custom-control-label" for="is_admin_delete_1"></label> 
                                    </div>
                                </span>
                            </td>
                            <td>
                                <span data-toggle="tooltip" title="" data-bs-original-title="View" aria-label="View" aria-describedby="">
                                    <div ClassName="custom-control custom-checkbox">
                                        <Input type="checkbox" ClassName="custom-control-input cloud_menu" data-id="1" data-key="view" data-col="is_services_agent" id="is_services_agent_view_1"/> 
                                        <label ClassName="custom-control-label" for="is_services_agent_view_1"></label> 
                                    </div>
                                </span>

                                <span data-toggle="tooltip" title="" data-bs-original-title="Add" aria-label="Add" aria-describedby="">
                                    <div ClassName="custom-control custom-checkbox">
                                        <Input type="checkbox" ClassName="custom-control-input cloud_menu" data-id="1" data-key="add" data-col="is_services_agent" id="is_services_agent_add_1"/> 
                                        <label ClassName="custom-control-label" for="is_services_agent_add_1"></label> 
                                    </div>
                                </span>

                                <span data-toggle="tooltip" title="" data-bs-original-title="Delete" aria-label="Delete" aria-describedby="">
                                    <div ClassName="custom-control custom-checkbox">
                                        <Input type="checkbox" ClassName="custom-control-input cloud_menu" data-id="1" data-key="delete" data-col="is_services_agent" id="is_services_agent_delete_1"/> 
                                        <label ClassName="custom-control-label" for="is_services_agent_delete_1"></label> 
                                    </div>
                                </span>
                            </td>
                            <td>
                                <span data-toggle="tooltip" title="" data-bs-original-title="View" aria-label="View" aria-describedby="">
                                    <div ClassName="custom-control custom-checkbox">
                                        <Input type="checkbox" ClassName="custom-control-input cloud_menu" data-id="1" data-key="view" data-col="is_accounting_manager" id="is_accounting_manager_view_1" checked=""/> 
                                        <label ClassName="custom-control-label" for="is_accounting_manager_view_1"></label> 
                                    </div>
                                </span>

                                <span data-toggle="tooltip" title="" data-bs-original-title="Add" aria-label="Add" aria-describedby="">
                                    <div ClassName="custom-control custom-checkbox">
                                        <Input type="checkbox" ClassName="custom-control-input cloud_menu" data-id="1" data-key="add" data-col="is_accounting_manager" id="is_accounting_manager_add_1" checked=""/> 
                                        <label ClassName="custom-control-label" for="is_accounting_manager_add_1"></label> 
                                    </div>
                                </span>

                                <span data-toggle="tooltip" title="" data-bs-original-title="Delete" aria-label="Delete" aria-describedby="">
                                    <div ClassName="custom-control custom-checkbox">
                                        <Input type="checkbox" ClassName="custom-control-input cloud_menu" data-id="1" data-key="delete" data-col="is_accounting_manager" id="is_accounting_manager_delete_1" checked=""/> 
                                        <label ClassName="custom-control-label" for="is_accounting_manager_delete_1"></label> 
                                    </div>
                                </span>
                
                            </td>
                            <td>
                                <span data-toggle="tooltip" title="" data-bs-original-title="View" aria-label="View" aria-describedby="">
                                    <div ClassName="custom-control custom-checkbox">
                                        <Input type="checkbox" ClassName="custom-control-input cloud_menu" data-id="1" data-key="view" data-col="is_sales_manager" id="is_sales_manager_view_1"/> 
                                        <label ClassName="custom-control-label" for="is_sales_manager_view_1"></label> 
                                    </div>
                                </span>

                                <span data-toggle="tooltip" title="" data-bs-original-title="Add" aria-label="Add" aria-describedby="">
                                    <div ClassName="custom-control custom-checkbox">
                                        <Input type="checkbox" ClassName="custom-control-input cloud_menu" data-id="1" data-key="add" data-col="is_sales_manager" id="is_sales_manager_add_1"/> 
                                        <label ClassName="custom-control-label" for="is_sales_manager_add_1"></label> 
                                    </div>
                                </span>

                                <span data-toggle="tooltip" title="" data-bs-original-title="Delete" aria-label="Delete" aria-describedby="">
                                    <div ClassName="custom-control custom-checkbox">
                                        <Input type="checkbox" ClassName="custom-control-input cloud_menu" data-id="1" data-key="delete" data-col="is_sales_manager" id="is_sales_manager_delete_1"/> 
                                        <label ClassName="custom-control-label" for="is_sales_manager_delete_1"></label> 
                                    </div>
                                </span>
                            </td>
                            <td>
                                <span data-toggle="tooltip" title="" data-bs-original-title="View" aria-label="View" aria-describedby="">
                                    <div ClassName="custom-control custom-checkbox">
                                        <Input type="checkbox" ClassName="custom-control-input cloud_menu" data-id="1" data-key="view" data-col="is_call_center_staff" id="is_call_center_staff_view_1"/> 
                                        <label ClassName="custom-control-label" for="is_call_center_staff_view_1"></label> 
                                    </div>
                                </span>

                                <span data-toggle="tooltip" title="" data-bs-original-title="Add" aria-label="Add" aria-describedby="">
                                    <div ClassName="custom-control custom-checkbox">
                                        <Input type="checkbox" ClassName="custom-control-input cloud_menu" data-id="1" data-key="add" data-col="is_call_center_staff" id="is_call_center_staff_add_1"/> 
                                        <label ClassName="custom-control-label" for="is_call_center_staff_add_1"></label> 
                                    </div>
                                </span>

                                <span data-toggle="tooltip" title="" data-bs-original-title="Delete" aria-label="Delete" aria-describedby="">
                                    <div ClassName="custom-control custom-checkbox">
                                        <Input type="checkbox" ClassName="custom-control-input cloud_menu" data-id="1" data-key="delete" data-col="is_call_center_staff" id="is_call_center_staff_delete_1"/> 
                                        <label ClassName="custom-control-label" for="is_call_center_staff_delete_1"></label> 
                                    </div>
                                </span>
                            </td>
                            <td>
                                <span data-toggle="tooltip" title="" data-bs-original-title="View" aria-label="View" aria-describedby="">
                                    <div ClassName="custom-control custom-checkbox">
                                        <Input type="checkbox" ClassName="custom-control-input cloud_menu" data-id="1" data-key="view" data-col="is_logistics_manager" id="is_logistics_manager_view_1"/> 
                                        <label ClassName="custom-control-label" for="is_logistics_manager_view_1"></label> 
                                    </div>
                                </span>

                                <span data-toggle="tooltip" title="" data-bs-original-title="Add" aria-label="Add" aria-describedby="">
                                    <div ClassName="custom-control custom-checkbox">
                                        <Input type="checkbox" ClassName="custom-control-input cloud_menu" data-id="1" data-key="add" data-col="is_logistics_manager" id="is_logistics_manager_add_1"/> 
                                        <label ClassName="custom-control-label" for="is_logistics_manager_add_1"></label> 
                                    </div>
                                </span>

                                <span data-toggle="tooltip" title="" data-bs-original-title="Delete" aria-label="Delete" aria-describedby="">
                                    <div ClassName="custom-control custom-checkbox">
                                        <Input type="checkbox" ClassName="custom-control-input cloud_menu" data-id="1" data-key="delete" data-col="is_logistics_manager" id="is_logistics_manager_delete_1"/> 
                                        <label ClassName="custom-control-label" for="is_logistics_manager_delete_1"></label> 
                                    </div>
                                </span>
                            </td>
                        </tr>   */}
                    </tbody>
                </table>
            </div>
        </PreviewCard>
    </Block>
</Content>
</React.Fragment>
  );
};

export default UserRoles;
