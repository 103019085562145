import React, { useState } from "react";
import Content from "../../layout/content/Content";
import "../../assets/css/AddUser.css";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  ReactDataTable,
  Icon,
} from "../../components/Component";
import makeAnimated from "react-select/animated";
import Head from "../../layout/head/Head";
import {userlog,userLogDataTableColumns} from "../components/table/TableData";
import { Link } from "react-router-dom";
const UserLog = () => {
  const [file, setFile] = useState("");

  return (
    <React.Fragment>
      <Head title="Basic Tables" />
      <Content page="component">
        <BlockHead size="lg" wide="sm">
        </BlockHead>

        <Block size="lg">
          <BlockHead className={`d-flex`}>
            <BlockHeadContent>
              <BlockTitle tag="h3">Admin User Logs
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent className={`ms-auto`}>
              <Link to={"/user-list"} className="btn btn-outline-light bg-white mx-1 tooltip_toggler" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="User List">
                <Icon className="ni ni-list"></Icon>
              </Link>
            </BlockHeadContent>
          </BlockHead>

          <PreviewCard>
            <ReactDataTable data={userlog} columns={userLogDataTableColumns} 
               expandableRows pagination actions className="custom-user-log-header"/>
          </PreviewCard>
        </Block>

      </Content>
    </React.Fragment>
  );
};

export default UserLog;
