import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import "../../assets/css/AddUser.css";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  ReactDataTable,
  Icon,
} from "../../components/Component";
import makeAnimated from "react-select/animated";
import Head from "../../layout/head/Head";
import { AdminNewsdataTableColumns, AdminNews } from "../components/table/TableData";
import { Link } from "react-router-dom";
import { getCookie } from "../../utils/Utils";
import NewsAction from "./NewsAction";
import { toast } from "react-toastify";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { ShimmerTable } from "react-shimmer-effects";
const AdminNewsList = () => {
  const [file, setFile] = useState("");
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [lastPage, setLastPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    const fetchBlogsFromAPI = async () => {
      try {
        const authToken = getCookie("authToken");

        const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-list-blog?current_page=${currentPage}&limit=${limit}`, {
          headers: {
            authToken: authToken,
          },
          method: "POST",
        });

        if (response.ok) {
          const responseData = await response.json();
          if (responseData.status) {
            setBlogs(responseData.data.blogs);
            setLastPage(responseData.data.pagination.last_page);
            console.log(responseData.data.pagination.last_page);
            setLimit(responseData.data.pagination.per_page);
          } else {
          }
        } else {
          console.log("Error fetching blogs:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
        // throw error;
      } finally {
        setLoading(false);
      }
    };
    fetchBlogsFromAPI();
  }, [currentPage,limit]);

  const handleDeleteBlog = (id) => {
    // setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog.id !== id));
    const updatedBlogs = blogs.filter((blog) => blog.id !== id);
    setBlogs(updatedBlogs);

    if (updatedBlogs.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const [serialNumbers, setSerialNumbers] = useState({});
  useEffect(() => {
    const updatedSerialNumbers = {};
    blogs.forEach((blog, index) => {
      updatedSerialNumbers[blog.id] = index + 1;
    });
    setSerialNumbers(updatedSerialNumbers);
  }, [blogs]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
  };

  return (
    <React.Fragment>
      <Head title="Blog CMS" />
      <Content page="component">
        {/* <BlockHead size="lg" wide="sm"></BlockHead> */}

        <Block size="lg" className="news-list-table">
          <BlockHead className={`d-flex`}>
            <BlockHeadContent>
              <BlockTitle tag="h4">Blog List</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent className={`ms-auto`}>
              <Link
                to={"/createblog"}
                className="btn btn-outline-light text-white userlist mx-1 tooltip_toggler"
                title="Add blogs"
              >
                <Icon className="ni ni-plus me-2"></Icon> Add Blog
              </Link>
            </BlockHeadContent>
          </BlockHead>

          <PreviewCard>
                {loading ? (
                  <ShimmerTable row={10} />
                ) : blogs.length > 0 ? (
              <>
                <ReactDataTable
                  data={blogs}
                  columns={AdminNewsdataTableColumns.map((col) => {
                    if (col.name === "Sr No") {
                      return {
                        ...col,
                        // cell: (row) => <div className="user-card mt-2 mb-2 ms-3">{serialNumbers[row.id]}</div>,
                        cell: (row, index) => (
                          <div className="user-card mt-2 mb-2 ms-3">
                            {index + 1 + (currentPage - 1) * itemsPerPage}
                          </div>
                        ),
                      };
                    }
                    if (col.name === "Action") {
                      return {
                        ...col,
                        cell: (row) => <NewsAction row={row} onDelete={handleDeleteBlog} />,
                      };
                    }
                    return col;
                  })}
                  pagination
                  actions
                  totalItems={lastPage * itemsPerPage}
                    paginate={paginate}
                    rowsPerPage={limit}
                    setRowsPerPage={setLimit}
                  className="custom-user-news-list"
                />
              </>
            ) : (
              <div className="text-center">No Records Found</div>
            )}
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default AdminNewsList;
