import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import { Link, useNavigate } from "react-router-dom";
import {  Label, Input, Row, Col } from "reactstrap";
import "../../assets/css/AddUser.css";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  Icon,
  RSelect,
} from "../../components/Component";
import Head from "../../layout/head/Head";
// hooks
import useShowHidePassword from "../../hooks/use-show-hide-password";
import { toast } from "react-toastify";
import { getCookie } from "../../utils/Utils";
import Cropper from "./Cropper";

const AddUser = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { showPassword, showRePassword, togglePassword} = useShowHidePassword()
  const [previewUrl, setPreviewUrl] = useState("");
  const [countries, setCountries] = useState([]);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    country: "",
    phone: "",
    password: "",
    rePassword: "",
    role_id:"3",
  });
  const [blogImg, setBlogImg] = useState({
    file: null,
    preview: "https://demo.silocloud.org/assets/cloud/images/launch/img/download.png",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async () => {
    try {
      setFormData({
        username: "",
        email: "",
        country: "",
        phone: "",
        password: "",
        rePassword: "",
        role_id:"",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleDropChange = (acceptedFiles, type) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      if (type === "blog_img") {
        setBlogImg({
          file: file,
          preview: reader.result,
        });
      } 
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
 
  const previewProfileImage = () => {
    const input = document.getElementById('profile_image');
    const file = input.files[0];
    const reader = new FileReader();
    const img = document.getElementById('uploadProfileImage');

    reader.onloadend = () => {
        img.src = reader.result;
    }

    if (file) {
        reader.readAsDataURL(file);
    }
};

useEffect(() => {
  const fetchCountriesFromAPI = async () => {
    try {
      const authToken = getCookie("authToken");

      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/countries`, {
        headers: {
          authToken: authToken,
        },
        method: "GET",
      });

      if (response.ok) {
        const responseData = await response.json();
          if (responseData.status) {
            setCountries(responseData.data.countries);
          } else {
        }
      } else {
          console.log("Error fetching blogs:", response.statusText);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false); 
    }
  };
  fetchCountriesFromAPI();
}, []);

  const handleCreateUserClick = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    console.log(formdata);
    formdata.append("username", formData.username);
    formdata.append("email", formData.email);
    formdata.append("phone_number", formData.phone);
    formdata.append("country", formData.country);
    formdata.append("password", formData.password);
    formdata.append("password_confirmation", formData.rePassword);
    formdata.append("role_id", formData.role_id);

   
    if (blogImg.file) {
      formdata.append("profile_image_path", blogImg.file);
    }

    let apiUrl = `${process.env.REACT_APP_API_URL}api/v1/admin/user-management/add-user`;

    try {
      setLoading(true);
      const response = await fetch(apiUrl, {
        headers: {
          authToken: getCookie("authToken"),
        },
        method: "POST",
        body: formdata,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.status) {
          toast.success(data.message);
          setTimeout(() => {
            navigate("/user-list");
          }, 3000);
        } else {
          if (data?.errors) {
            toast.error(data?.errors[0]);
          } else {
            toast.error(data.message);
          }
        }
      } else {
        toast.error("Error adding blog");
      }
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Head title="Form Elements" />
      <Content >
        <BlockHead size="lg" className={`d-flex`}>
          <BlockHeadContent>
            <BlockTitle tag="h4">
              Create User
            </BlockTitle>
          </BlockHeadContent>
          <BlockHeadContent className={`ms-auto`}>
            <Link to={"/user-list"} className="btn btn-outline-light text-white  mx-1 tooltip_toggler userlist" title="User List">
              <Icon className="ni ni-list me-2"></Icon> User List
            </Link>
          </BlockHeadContent>
        </BlockHead>

        <Block size="lg">
          <PreviewCard> 
            <Row className="gy-4">
              <Col sm="6" className="">
                <div className="form-group text-center">
                  <div className="form-control-wrap mt-5">
                    <Cropper />
                  </div>
                </div>
              </Col>
              <Col sm="6" className=""> 
                <div className="form-group">
                  <Label htmlFor="username" className="form-label">
                    Username<span className="text-danger"> *</span>
                  </Label>
                  <div className="form-control-wrap">
                    <Input className="form-control" type="text" id="username" name="username" placeholder="" value={formData.username}
                        onChange={(e) => setFormData({ ...formData, username: e.target.value })}></Input>
                  </div>
                </div>
                <div className="form-group">
                  <Label htmlFor="email" className="form-label">
                    Email Address<span className="text-danger"> *</span>
                  </Label>
                  <div className="form-control-wrap">
                    <Input className="form-control" type="text" id="email" name="email" placeholder="" value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}></Input>
                  </div>
                </div>
                <Row>
                  <Col sm="6">
                    <div className="form-group">
                      <Label htmlFor="country" className="form-label">
                      Country<span className="text-danger"> *</span>
                      </Label>
                      <div className="form-control-wrap">
                        <div className="form-control-select">
                          <Input
                              type="select" name="country" id="country"
                              value={formData.country}
                              onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                            >
                              {countries.map((country, index) => (
                              <option key={index} value={country.phonecode} >
                              {country.name}
                            </option>
                            ))}
                            </Input>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="form-group">
                      <Label htmlFor="phone" className="form-label">
                        Phone Number<span className="text-danger"> *</span>
                      </Label>
                      <div className="form-control-wrap">
                      <Input className="form-control" type="number" id="phone" name="phone_number" placeholder="" value={formData.phone}
                            onChange={(e) => setFormData({ ...formData, phone: e.target.value })}></Input>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm="6">
                <Row>
                  <Col sm="6">
                    <div className="form-group">
                      <Label htmlFor="password" className="form-label">
                        Password<span className="text-danger"> *</span>
                      </Label>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-right">
                            <Icon className={`icon ni ${showPassword ? 'ni-eye-off' : 'ni-eye'}`} id="togglePassword" aria-hidden="true" onClick={() => togglePassword('password')}></Icon>
                        </div>
                        <Input className="form-control" type={showPassword ? "text" : "password"} name="password" id="password" placeholder="" value={formData.password}
                            onChange={(e) => setFormData({ ...formData, password: e.target.value })}></Input>
                      </div>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="form-group">
                      <Label htmlFor="role" className="form-label">
                      Re-Enter Password<span className="text-danger"> *</span>
                      </Label>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-right">
                            <Icon className={`ni ${showRePassword ? 'ni-eye-off' : 'ni-eye'}`} id="togglePassword" aria-hidden="true" onClick={() => togglePassword('re-password')}></Icon>
                        </div>
                        <input className="form-control" type={showRePassword ? 'text' : 'password'} name="password_confirmation" id="rePassword" placeholder="" value={formData.rePassword}
                            onChange={(e) => setFormData({ ...formData, rePassword: e.target.value })}/>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm="6">
                <div className="form-group">
                  <Label htmlFor="role_id" className="form-label">
                    Role
                  </Label>
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                        <Input
                          type="select"
                          name="role_id"
                          id="role_id"
                          onChange={(e) => {
                            setFormData({ ...formData, role_id: e.target.value });
                          }}
                          value={formData.role_id || "3"}
                        >
                          <option value="3">User</option>
                          <option value="2">Admin</option>
                          {/* <option value="3">User</option> */}
                        </Input>
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm="12">
                <div className="form-group text-center">
                    <button type="submit" className="btn text-white userlist" id="btn-register" name="btn-register" onClick={handleCreateUserClick}>Create User</button>
                </div>
             </Col>
            </Row>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default AddUser;
