import React, { useEffect, useState } from "react";
import {useParams} from "react-router-dom"
import {dataTableColumns2 } from "../components/table/TableData";
import Content from "../../layout/content/Content";
import { Link } from "react-router-dom";
// import MediaInformation from "./MediaInformation";
import ContactInformation from "./ContactInformation";
// import DoctorInformation from "./DoctorInformation";
import {Label,Input, Row, Col } from "reactstrap";
import {
    BlockHead,
    BlockHeadContent,
    BlockTitle,
    PreviewCard,
    Icon,
    Block,
  } from "../../components/Component";
  import Head from "../../layout/head/Head";
  // hooks
import useShowHidePassword from "../../hooks/use-show-hide-password";
import PersonalInformation from "./PersonalInformation";
import { getCookie } from "../../utils/Utils";
import { toast } from "react-toastify";
import "../../../src/assets/css/AddUser.css";

export default function EditUser() {
    const { showPassword, showRePassword, togglePassword} = useShowHidePassword()
    const id = useParams(); 
    const [SpecificUser, setUser] = useState([]);
    const userId = parseInt(id.userId);
    const user = dataTableColumns2.find(user => user.id === userId);
    const [formdata, setFormData] = useState({
        password: "",
        password_confirmation: "",
    
      });

      useEffect(() => {
        const fetchUserData = async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/admin/user-management/get-specific-user`;
                const formData = new FormData();

                formData.append("id", userId);
                const response = await fetch(apiUrl, {
                    headers: {
                        authToken: getCookie("authToken"),
                    },
                    method: "POST",
                    body: formData,
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data.status) {
                        setUser(data.data?.user);
                    } else {
                        console.error("Error fetching blog data", data.message);
                    }
                } else {
                    console.error("Error fetching blog data", response.statusText);
                }
            } catch (error) {
                console.error("Fetch error:", error);
            }
        };
        fetchUserData();
    }, [id]);
   
    const handleUpdateAccountPassword = async () => {
        try {
          const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/admin/user-management/update-Account-Password`;
          const formData = new FormData();

          formData.append("id", userId);
          formData.append("password", formdata.password);
          formData.append("password_confirmation", formdata.password_confirmation);
    
          const response = await fetch(apiUrl, {
            headers: {
              authToken: getCookie("authToken"),
            },
            method: "POST",
            body: formData,
          });
    
          if (response.ok) {
            const data = await response.json();
            if (data.status) {
              toast.success("Account password updated successfully");
              setTimeout(() => {
              }, 3000);
            } else {
                if (data?.errors) {
                    toast.error(data?.errors[0]);
                } else {
                    toast.error(data.message);
                }
            }
          } else {
            console.error("Error updating profile", response.statusText);
          }
        } catch (error) {
          console.error("Update error:", error);
        }
      };
    return (
        <React.Fragment>
            <Head title="Form Elements" />
            <Content >
                <BlockHead size="lg" className={`d-flex`}>
                    <BlockHeadContent>
                        <BlockTitle tag="h4">
                        Edit User/<strong className="text-theme-color small">{SpecificUser.username}</strong>
                        </BlockTitle>
                    </BlockHeadContent>
                    <BlockHeadContent className={`ms-auto`}>
                        <Link to={"/user-list"} className="btn btn-outline-light text-white userlist mx-1 tooltip_toggler" title="User List">
                        <Icon className="ni ni-list me-2"></Icon> User List
                        </Link>
                    </BlockHeadContent>
                </BlockHead>
                <ContactInformation />
                <PersonalInformation />
                <Block size="lg">
                    <PreviewCard>
                        <Row className="g-4">
                            <Col md="12" className="">
                                <div className="">
                                    <div className="card-head">
                                        <h5 className="card-title">Account - Password</h5>
                                    </div>
                                    <Row>
                                        <Col md="6">
                                            <div className="form-group">
                                                <Label className="form-label" htmlFor="password">Change Password</Label>
                                                <div className="form-control-wrap">
                                                    <div className="form-icon form-icon-right">
                                                        <Icon className={`ni ${showPassword ? 'ni-eye-off' : 'ni-eye'}`} id="togglePassword" aria-hidden="true" onClick={() => togglePassword('password')}></Icon>
                                                    </div>
                                                    <Input type={showPassword ? "text" : "password"} className="form-control" id="password" name="password" value={formdata.password} onChange={(e) => setFormData({ ...formdata, password: e.target.value })}/>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md="6">
                                            <div className="form-group">
                                                <Label className="form-label" htmlFor="cPassword">Confirm Password</Label>
                                                <div className="form-control-wrap">
                                                    <div className="form-icon form-icon-right">
                                                        <Icon className={`ni ${showRePassword ? 'ni-eye-off' : 'ni-eye'}`} id="togglePassword" aria-hidden="true" onClick={() => togglePassword('re-password')}></Icon>
                                                    </div>
                                                    <Input type={showRePassword ? 'text' : 'password'} className="form-control" id="re-password" name="password_confirmation" value={formdata.password_confirmation} onChange={(e) => setFormData({ ...formdata, password_confirmation: e.target.value })} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="form-group">
                                    <button type="button" className="btn userlist text-white" onClick={handleUpdateAccountPassword}>Save Password</button>
                                </div>
                            </Col>
                        </Row>
                    </PreviewCard>
                </Block>
            </Content>
        </React.Fragment>
    );
}

