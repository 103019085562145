import React, { useState, useEffect } from "react";
import Content from "../../layout/content/Content";
import "../../assets/css/AddUser.css";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  ReactDataTable,
  Icon,
} from "../../components/Component";
import makeAnimated from "react-select/animated";
import Head from "../../layout/head/Head";
import {dataTableColumns2} from "../components/table/TableData";
import { Link } from "react-router-dom";
import { getCookie } from "../../utils/Utils";
import Userdata from "./Userdata";
import { toast } from "react-toastify";
import { Input, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { ShimmerTable } from "react-shimmer-effects";
const UserEvent = () => {
  const [file, setFile] = useState("");
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  //add new to manage role
  const [selectedRole, setSelectedRole] = useState("");
  const [serialNumbers, setSerialNumbers] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    const fetchBlogsFromAPI = async () => {
      try {
        const authToken = getCookie("authToken");

        const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/user-management/get-user?current_page=${currentPage}&limit=${limit}`, {
          headers: {
            authToken: authToken,
          },
          method: "POST",
        });

        if (response.ok) {
          const responseData = await response.json();
            if (responseData.status) {
              setUsers(responseData.data.users);
              setLastPage(responseData.data.pagination.last_page);
              setLimit(responseData.data.pagination.per_page);
            } else {
          }
        } else {
            console.log("Error fetching blogs:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch error:", error);
        // throw error;
      } finally {
        setLoading(false); 
      }
    };
    fetchBlogsFromAPI();
  }, [currentPage,limit]);
  
  useEffect(() => {
    const updatedSerialNumbers = {};
    users.forEach((user, index) => {
      updatedSerialNumbers[user.id] = index + 1;
    });
    setSerialNumbers(updatedSerialNumbers);
  }, [users]);

  const handleRoleChange = async (roleId, username) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/admin/user-management/update-role`;
      const formData = new FormData();
      formData.append("username", username);
      formData.append("role_id", roleId);

      const response = await fetch(apiUrl, {
        headers: {
          authToken: getCookie("authToken"),
        },
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.status) {
          toast.success("Role updated successfully");

          setUsers((prevUsers) =>
            prevUsers.map((user) =>
              user.username === username ? { ...user, role_id: roleId } : user
            )
          );
        } else {
          if (data?.errors) {
            toast.error(data?.errors[0]);
          } else {
            toast.error(data.message);
          }
        }
      } else {
        console.error("Error updating profile", response.statusText);
      }
    } catch (error) {
      console.error("Update error:", error);
    }
  };

  const handleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const handleDeleteUser = (id) => {
    // setUsers((prevBlogs) => prevBlogs.filter((user) => user.id !== id));
    const updatedUsers = users.filter((user) => user.id !== id);
    setUsers(updatedUsers);

    if (updatedUsers.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <React.Fragment>
      <Head title="User CMS" />
      <Content page="component">
        <Block size="lg" className="user-list-table">
          <BlockHead className={`d-flex`}>
            <BlockHeadContent>
              <BlockTitle tag="h4">User List
              </BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent className={`ms-auto`}>
              <Link
                to={"/create"}
                className="btn btn-outline-light text-white mx-1 tooltip_toggler userlist"
                title="Add User"
              >
                <Icon className="ni ni-plus me-2"></Icon> Add User
              </Link>
            </BlockHeadContent>
          </BlockHead>

          <PreviewCard>
               {loading ? (
                  <ShimmerTable row={10} />
                ) : users.length > 0 ? (
                <>
                  <ReactDataTable
                    data={users}
                    columns={dataTableColumns2.map((col) => {
                      if (col.name === "Sr No") {
                        return {
                          ...col,
                          // cell: (row) => (
                          //   <div className="user-card mt-2 mb-2 ms-3">
                          //     {serialNumbers[row.id]}
                          //   </div>
                          // ),
                          cell: (row, index) => (
                            <div className="user-card mt-2 mb-2 ms-3">
                              {index + 1 + (currentPage - 1) * limit} 
                              {/* itemsPerPage */}
                            </div>
                          ),
                        };
                      } else if (col.name === "Role") {
                        return {
                          ...col,
                          cell: (row) => (
                            <div className="news ms-4">
                              <div className="form-control-wrap">
                                <div className="form-control-select">
                                  <Input
                                    type="select"
                                    name="role_id"
                                    id="role_id"
                                    value={row.role_id}
                                    onChange={(e) =>
                                      handleRoleChange(e.target.value, row.username)
                                    }
                                  >
                                    <option value="3">User</option>
                                    <option value="2">Admin</option>
                                  </Input>
                                </div>
                              </div>
                            </div>
                          ),
                        };
                      } else if (col.name === "Action") {
                        return {
                          ...col,
                          cell: (row) => (
                            <Userdata
                              row={row}
                              onDelete={handleDeleteUser}
                              selectedRole={selectedRole}
                            />
                          ),
                        };
                      }
                      return col;
                    })}
                    expandableRows
                    pagination
                    actions
                    totalItems={lastPage * itemsPerPage}
                    paginate={paginate}
                    rowsPerPage={limit}
                    setRowsPerPage={setLimit}
                    className="custom-user-news-list"
                  />
                </>
            ) : (
              <div className="text-center">No Records Found</div>
            )}
          </PreviewCard>
        </Block>

      </Content>
    </React.Fragment>
  );
};

export default UserEvent;
