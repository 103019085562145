import React, { useState, createContext } from "react";
import Content from "../../layout/content/Content";
import "../../assets/css/AddUser.css";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
  ReactDataTable,
  Icon,
} from "../../components/Component";
import makeAnimated from "react-select/animated";
import Head from "../../layout/head/Head";
import { dataTableColumns2, userData, recentusers, recentUserDataTableColumns } from "../components/table/TableData";
import { Link } from "react-router-dom";
import Error404Modern from "../error/404-modern";
import ContactForm from "./ContactForm";
import ConsultingForm from "./ConsultingForm";

const formNo = createContext();

const UserEvent = () => {
  const [file, setFile] = useState("");
  const [forms, showForms] = useState(0);
  const [activebtn, setActivebtn] = useState(0);

  const setActiveButton = (index) => {
    setActivebtn(index);
    showForms(index);
  };

  return (
    <React.Fragment>
      <Head title="User Management" />
      <Content page="component">
        {/* <BlockHead size="lg" wide="sm">
        </BlockHead> */}

        <Block size="lg" className="user-list-table">
          <BlockHead className={`d-flex`}>
            <BlockHeadContent>
              <BlockTitle tag="h3">Feedback And Request</BlockTitle>
              <button
                onClick={() => setActiveButton(0)}
                className={`btn mx-1 my-1 tooltip_toggler ${
                  activebtn === 0 ? "bg-danger text-white" : "bg-white text-danger"
                }`}
                title="Add blogs"
              >
                Contact Us
              </button>
              <button
                onClick={() => setActiveButton(1)}
                className={`btn mx-1 my-1 tooltip_toggler ${
                  activebtn === 1 ? "bg-danger text-white" : "bg-white  text-danger"
                }`}
                title="Add blogs"
              >
                Request
              </button>
            </BlockHeadContent>
            <BlockHeadContent className={`ms-auto`}></BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <formNo.Provider value={forms}>
              {forms === 0 && <ContactForm />}
              {forms === 1 && <ConsultingForm />}
            </formNo.Provider>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default UserEvent;
export { formNo };
