import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"
import { userData } from "../components/table/TableData";
import Content from "../../layout/content/Content";
import { Label, Input, Row, Col } from "reactstrap";
import {
    Block,
    PreviewCard,
    Icon,
} from "../../components/Component";
import Head from "../../layout/head/Head";
import { getCookie } from "../../utils/Utils";
import { toast } from "react-toastify";

const PersonalInformation = () => {
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(false);
    const id = useParams();
    const userId = parseInt(id.userId);
    const user = userData.find(user => user.id === userId);
    const [SpecificUser, setUser] = useState([]);
    const [formdata, setFormData] = useState({
        first_name: "",
        last_name: "",
        state:"",
        city:"",
        about_me:"",
        zip_code:"",
        country: "",
    
      });

    useEffect(() => {
        const fetchCountriesFromAPI = async () => {
          try {
            const authToken = getCookie("authToken");
      
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/countries`, {
              headers: {
                authToken: authToken,
              },
              method: "GET",
            });
      
            if (response.ok) {
              const responseData = await response.json();
                if (responseData.status) {
                  setCountries(responseData.data.countries);
                } else {
              }
            } else {
                console.log("Error fetching blogs:", response.statusText);
            }
          } catch (error) {
            console.error("Fetch error:", error);
          } finally {
            setLoading(false); 
          }
        };
        fetchCountriesFromAPI();
      }, []);

      useEffect(() => {
        const fetchUserData = async () => {
            try {
                const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/admin/user-management/get-specific-user`;
                const formData = new FormData();

                formData.append("id", userId);
                const response = await fetch(apiUrl, {
                    headers: {
                        authToken: getCookie("authToken"),
                    },
                    method: "POST",
                    body: formData,
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data.status) {
                        setUser(data.data?.user);
                        const user_data = data.data.user;
                        setFormData({
                            first_name: user_data.first_name1 || "",
                            last_name: user_data.last_name1 || "",
                            city: user_data.city1 || "",
                            state: user_data.state1 || "",
                            zip_code: user_data.zip_code1 || "",
                            about_me: user_data.about_me || "",
                            country: user_data.country || "",
                        });
                    } else {
                        console.error("Error fetching blog data", data.message);
                    }
                } else {
                    console.error("Error fetching blog data", response.statusText);
                }
            } catch (error) {
                console.error("Fetch error:", error);
            }
        };
        fetchUserData();
    }, [id]);
   
    const handleUpdatePersonalInformation = async () => {
        try {
          const apiUrl = `${process.env.REACT_APP_API_URL}api/v1/admin/user-management/update-personal-Information`;
          const formData = new FormData();
          formData.append("id", userId);
          formData.append("first_name", formdata.first_name);
          formData.append("last_name", formdata.last_name);
          formData.append("state", formdata.state);
          formData.append("city", formdata.city);
          formData.append("about_me", formdata.about_me);
          formData.append("zip_code", formdata.zip_code);
          formData.append("country", formdata.country);
    
          const response = await fetch(apiUrl, {
            headers: {
              authToken: getCookie("authToken"),
            },
            method: "POST",
            body: formData,
          });
    
          if (response.ok) {
            const data = await response.json();
            if (data.status) {
              toast.success("Personal information updated successfully");
              setTimeout(() => {
              }, 3000);
            } else {
                if (data?.errors) {
                    toast.error(data?.errors[0]);
                } else {
                    toast.error(data.message);
                }
            }
          } else {
            console.error("Error updating profile", response.statusText);
          }
        } catch (error) {
          console.error("Update error:", error);
        }
      };
    return (
        <Block size="lg">
            <PreviewCard>
                <div className="card-head">
                    <h5 className="card-title">Personal Information</h5>
                </div>
                {/* <form action="#"> */}
                    <Row className="g-4">
                        <Col lg="6" className="">
                            <div className="form-group">
                                <Label className="form-label" htmlFor="firstname">First Name</Label>
                                <Input id="firstname" type="text" className="form-control" value={formdata.first_name}
                        onChange={(e) => setFormData({ ...formdata, first_name: e.target.value })} />
                            </div>
                        </Col>
                        <Col lg="6" className="">
                            <div class="form-group">
                                <Label class="form-label" htmlFor="lastname">Last Name</Label>
                                <Input id="lastname" type="text" class="form-control" value={formdata.last_name}
                        onChange={(e) => setFormData({ ...formdata, last_name: e.target.value })} />
                            </div>
                        </Col>
                        <Col lg="6" class="">
                            <div class="form-group">
                                <Label class="form-label" htmlFor="state">State</Label>
                                <div className="form-control-wrap">
                                    <div class="form-control-select">
                                        <Input type="select" class="" id="state" name="state" value={formdata.state} onChange={(e) => setFormData({ ...formdata, state: e.target.value })}>
                                            <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                            <option value="Andhra Pradesh">Andhra Pradesh</option>
                                            <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                            <option value="Assam">Assam</option>
                                            <option value="Bihar">Bihar</option>
                                            <option value="Chandigarh">Chandigarh</option>
                                            <option value="Chhattisgarh">Chhattisgarh</option>
                                            <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                                            <option value="Daman and Diu">Daman and Diu</option>
                                            <option value="Delhi">Delhi</option>
                                            <option value="Goa">Goa</option>
                                            <option value="Gujarat">Gujarat</option>
                                            <option value="Haryana">Haryana</option>
                                            <option value="Himachal Pradesh">Himachal Pradesh</option>
                                            <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                            <option value="Jharkhand">Jharkhand</option>
                                            <option value="Karnataka">Karnataka</option>
                                            <option value="Kenmore">Kenmore</option>
                                            <option value="Kerala">Kerala</option>
                                            <option value="Lakshadweep">Lakshadweep</option>
                                            <option value="Madhya Pradesh">Madhya Pradesh</option>
                                            <option value="Maharashtra">Maharashtra</option>
                                            <option value="Manipur">Manipur</option>
                                            <option value="Meghalaya">Meghalaya</option>
                                            <option value="Mizoram">Mizoram</option>
                                            <option value="Nagaland">Nagaland</option>
                                            <option value="Narora">Narora</option>
                                            <option value="Natwar">Natwar</option>
                                            <option value="Odisha">Odisha</option>
                                            <option value="Paschim Medinipur">Paschim Medinipur</option>
                                            <option value="Pondicherry">Pondicherry</option>
                                            <option value="Punjab">Punjab</option>
                                            <option value="Rajasthan">Rajasthan</option>
                                            <option value="Sikkim">Sikkim</option>
                                            <option value="Tamil Nadu">Tamil Nadu</option>
                                            <option value="Telangana">Telangana</option>
                                            <option value="Tripura">Tripura</option>
                                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                                            <option value="Uttarakhand">Uttarakhand</option>
                                            <option value="Vaishali">Vaishali</option>
                                            <option value="West Bengal">West Bengal</option>
                                        </Input>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col sm="6" className="">
                            <div className="form-group">
                                <Label htmlFor="userCity" className="form-label">City</Label>
                                <div className="form-control-wrap">
                                    <Input id="city" name="city" type="text" className="form-control" value={formdata.city}
                        onChange={(e) => setFormData({ ...formdata, city: e.target.value })} />
                                </div>
                            </div>
                        </Col>
                        <Col sm="6" className="">
                            <div className="form-group">
                                <Label className="form-label" for="zip_code">Zip Code</Label>
                                <Input id="zip_code" name="zip_code" type="number" className="form-control" value={formdata.zip_code}
                        onChange={(e) => setFormData({ ...formdata, zip_code: e.target.value })} />
                            </div>
                        </Col>
                        <Col lg="6" className="">
                            <div className="form-group">
                                <Label htmlFor="country" className="form-label">
                                    Country
                                </Label>
                                <div className="form-control-wrap">
                                    <div className="form-control-select">
                                        <Input type="select" name="select" id="country" value={formdata.country} onChange={(e) => setFormData({ ...formdata, country: e.target.value })}>
                                        {countries.map((country, index) => (
                                            <option key={index} value={country.phonecode} >
                                            {country.name}
                                            </option>
                                        ))}
                                        </Input>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className="col-12">
                            <Row className="">
                                <Col md="6" className="">
                                    <div className="form-group">
                                        <Label className="form-label" htmlFor="default-textarea">About Me</Label>
                                        <div className="form-control-wrap">
                                            <textarea id="about_me" className="form-control no-resize" value={formdata.about_me}
                                            onChange={(e) => setFormData({ ...formdata, about_me: e.target.value })}></textarea>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="col-12">
                            <div className="form-group">
                                <button id="savePersonalInformation" className="btn userlist text-white" onClick={handleUpdatePersonalInformation}>Save Personal Information</button>
                            </div>
                        </Col>
                    </Row>
                {/* </form> */}
            </PreviewCard>
        </Block>
    );
}

export default PersonalInformation;
