import React, { useState, useEffect } from "react";
import { Link} from "react-router-dom";
import {useParams} from "react-router-dom"
import {userData } from "../components/table/TableData";
import Content from "../../layout/content/Content";

import {
    Block,
    PreviewCard,
  } from "../../components/Component";
  import Head from "../../layout/head/Head";
import {Label,Input, Col, Row } from "reactstrap";

function PricingOverlay({ turnOffOverlay }) {
    const [showOverlay, setShowOverlay] = useState(false);

    const freePackageSubscription = (id, userId) => {
        // Logic for free package subscription
    };

    const toggleOverlay = () => {
        setShowOverlay(!showOverlay);
    };
    return (
        <div id="overlay-pricing-plans" style={{display: showOverlay ? "block" : "none", marginTop: "0px" }}>
            <div id="pricing-plans">
                <div className="overlay-toogle-class">
                    <div className="custom-control custom-switch">
                        <Label className="custom-control-label1" htmlFor="switch_package_services">Packages</Label>
                        <Input type="checkbox" className="custom-control-input" id="switch_package_services" />
                        <Label className="custom-control-label" htmlFor="switch_package_services">Services</Label>
                    </div>
                </div>
                <div id="package_container" className="">
                    <div className="container info">
                        <div className="row">
                            <div className="col-md-12 card_title_renew mt-3">
                                <p className="text-center">Disclaimer: Please note that if you choose to purchase a pack for this user, it will be considered a part of the free subscription provided by the administrator.</p>
                            </div>
                            <div className="close-overlay">
                                <Link tag="a" to="#" className="close" onClick={turnOffOverlay}></Link>
                            </div>
                        </div>
                    </div>
                    <div className="container packages">
                        <div className="grid">
                            <div className="card purchase-card h-100 Enthusiast mt-4">
                                <div className="card-head justify-content-center">
                                    <h2>Enthusiast</h2>
                                </div>
                                <div className="card-body">
                                    <p className="act">Activation</p>
                                    <p className="sub-text">Monthly Service Charges</p>
                                    <div className="price-details">
                                        <p className="pricing">$29</p>
                                        <Link tag="a" to="#" className="free_buy_btn" onClick={() => freePackageSubscription('8', '5261')}>Buy package</Link>
                                        <Link tag="a" to="#" className="see_btn">Show Details</Link>
                                        <div className="feature-ul d-none">
                                            <ul className="features list-group list-group-flush border-0 ">
                                                <li>File Manager</li>
                                                <li>Marketplace</li>
                                                <li>Open World</li>
                                                <li>Silo QR Code</li>
                                                <li>Silo Canvas</li>
                                                <li>Silo Chat</li>
                                                <li>Silo Community</li>
                                                <li>Silo Connect</li>
                                                <li>Silo Health</li>
                                                <li>Silo Music</li>
                                                <li>Silo Radio</li>
                                                <li>Silo Social media</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card purchase-card h-100 Enterprise mt-4">
                                <div className="card-head justify-content-center">
                                    <h2>Enterprise</h2>
                                </div>
                                <div className="card-body">
                                    <p className="act">Activation</p>
                                    <p className="sub-text">Monthly Service Charges</p>
                                    <div className="price-details">
                                        <p className="pricing">$59</p>
                                        <Link tag="a" to="#" className="free_buy_btn" onClick={() => freePackageSubscription('7', '5261')}>Buy package</Link>
                                        <Link tag="a" to="#" className="see_btn">Show Details</Link>
                                        <div className="feature-ul d-none">
                                            <ul className="features list-group list-group-flush border-0 ">
                                                <li>File Manager</li>
                                                <li>Marketplace</li>
                                                <li>Open World</li>
                                                <li>Silo QR Code</li>
                                                <li>Silo Canvas</li>
                                                <li>Silo Chat</li>
                                                <li>Silo Community</li>
                                                <li>Silo Connect</li>
                                                <li>Silo Health</li>
                                                <li>Silo Live Streaming Deck</li>
                                                <li>Silo Music</li>
                                                <li>Silo Radio</li>
                                                <li>Silo Social media</li>
                                                <li>Silo Suite</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card purchase-card h-100 Influencer mt-4">
                                <div className="card-head justify-content-center">
                                    <h2>Influencer</h2>
                                </div>
                                <div className="card-body">
                                    <p className="act">Activation</p>
                                    <p className="sub-text">Monthly Service Charges</p>
                                    <div className="price-details">
                                        <p className="pricing">$149</p>
                                        <Link tag="a" to="#" className="free_buy_btn" onClick={() => freePackageSubscription('4', '5261')}>Buy package</Link>
                                        <Link tag="a" to="#" className="see_btn">Show Details</Link>
                                        <div className="feature-ul d-none">
                                            <ul className="features list-group list-group-flush border-0 ">
                                                <li> Site Builder</li>
                                                <li>File Manager</li>
                                                <li>Marketplace</li>
                                                <li>Open World</li>
                                                <li>Silo QR Code</li>
                                                <li>Silo Canvas</li>
                                                <li>Silo Chat</li>
                                                <li>Silo Community</li>
                                                <li>Silo Connect</li>
                                                <li>Silo ERP</li>
                                                <li>Silo Health</li>
                                                <li>Silo Live Streaming Deck</li>
                                                <li>Silo Mining</li>
                                                <li>Silo Music</li>
                                                <li>Silo Radio</li>
                                                <li>Silo Social media</li>
                                                <li>Silo Suite</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PricingOverlay;