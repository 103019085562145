import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Appbar from "./appbar/Appbar";
import Sidebar from "./sidebar/Sidebar";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
const Layout = ({ title, app, ...props }) => {
  useEffect(() => {
    let at = Cookies.get("authToken");
    if (!at) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      window.location = process.env.REACT_APP_ACCOUNT_URL;
    } else {
    }
  }, []);

  return (

    <>
      <Head title={!title && "Loading"} />

      <AppRoot>
        <Appbar />
        <AppMain>
          <Sidebar fixed />
          <AppWrap>
            <Header fixed />
            <Outlet />
            <Footer />
          </AppWrap>
        </AppMain>
      </AppRoot>
    </>
  );
};
export default Layout;
