const menu = [
  {
    heading: "Dashboards",
  },
  // {
  //   icon: "user-list",
  //   text: "Manage Users",
  //   link: "/user-list",
  //   dashboard: true,
  // },
  {
    icon: "user-list",
    text: "Manage Users",
    link: "/user-list",
    dashboard: true,
  },
  {
    icon: "package",
    text: "Manage blogs",
    link: "/admin-manage-cms/admin-news-list",
    dashboard: true,
  },
  // {
  //   icon: "contact",
  //   text: "Contact Form",
  //   link: "/contact-form",
  //   dashboard: true,
  // },
  {
    icon: "setting",
    text: "Admin Site Settings",
    link: "/setting",
    dashboard: true,
  },
];
export default menu;
