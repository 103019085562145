import React from "react";
import PropTypes from "prop-types";
import { EditorState, Modifier } from "draft-js";

const CustomDescription = ({ editorState, onChange }) => {
//   const addStar = () => {
//     const contentState = Modifier.replaceText(
//       editorState.getCurrentContent(),
//       editorState.getSelection(),
//       "⭐",
//       editorState.getCurrentInlineStyle()
//     );
//     onChange(EditorState.push(editorState, contentState, "insert-characters"));
//   };

//   return <div onClick={addStar}>⭐</div>;
};

CustomDescription.propTypes = {
  onChange: PropTypes.func,
  editorState: PropTypes.object
};

export default CustomDescription;
